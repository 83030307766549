
var baseURL = window.location.protocol + "//" + window.location.host + "/";

if (window.location.href === baseURL) {

  // popup js code
  document.addEventListener("DOMContentLoaded", function () {
      popup = document.getElementById("popup");
      closeButton = document.querySelector(".popup-close");
  
      // Function to show the popup
    
      function showPopup() {
           if (document.cookie.indexOf("popupShown=true") === -1) {
              popup.style.display = 'flex';
              document.cookie = "popupShown=true";
            }
      }
  
      // Function to close the popup
      function closePopup() {
          popup.style.display = "none";
      }
  
      // Show the popup when the page loads
      showPopup();
  
      // Close the popup when the close button is clicked
      closeButton.addEventListener("click", closePopup);
  });
  
    document.addEventListener("DOMContentLoaded", function() {
    var customEmailLoyalty = document.getElementById('popupButton');
  
    customEmailLoyalty.addEventListener('click', function(e) {
      e.preventDefault();
      var emailValue = document.querySelector('input[name="contact[email]"]').value;
      window.location.href = "https://motebua.no/a/loyalty?email=" + encodeURIComponent(emailValue);
    });

    var customPopupLoyalty = document.getElementById('popupButton');
    customPopupLoyalty.addEventListener('click', function(e) {
      e.preventDefault();
      var emailPopValue = document.querySelector('input[id="popup-input-email').value;
      window.location.href = "https://motebua.no/a/loyalty?oic=shopify_popup&email=" + encodeURIComponent(emailPopValue);
    });
  
  });
} 



document.addEventListener("DOMContentLoaded", function() {
  
});

if (window.location.href.indexOf("/loyalty") !== -1) {
var urlParams = new URLSearchParams(window.location.search);
    
    if (urlParams.has('email')) {
        var email = urlParams.get('email');
        
        console.log('Email:', email);
        
        // You can use the email value as needed, for example:
        $("#email").val(email);
        // alert("Email: " + email); // Display an alert with the email value
    } else {
        console.log('Email parameter not found in the URL.');
    }
}
$(document).ready(function () {
  $('#custom-email-loyalty').click(function (e) { 
    e.preventDefault();
    var email_value = $('input[name="contact[email]"]').val();
    window.location.href = "https://motebua.no/a/loyalty?email=" + email_value;
    
  });
if ($(window).width() < 600) {
         $($('.search-form__container')).appendTo($('#SiteHeader .page-width'));
    } 
});



